import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import { createContext, useMemo, useState } from "react";
import Loading from "./components/Loading";

export const AppContext = createContext(null);

function App() {
  const [loading, setLoading] = useState(false);
  const value = useMemo(
    () => ({
      loading,
      setLoading,
    }),
    [loading]
  );

  return (
    <Router>
      <AppContext.Provider value={value}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="/signin" element={<SignIn />} />
        </Routes>
        {/* Loading */}
        <Loading enabled={loading} />
      </AppContext.Provider>
    </Router>
  );
}

export default App;
