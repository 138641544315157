import axios from "axios";

const API_URL = "https://api.mymoji.com.tw/";

const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.access_token) {
    return { Authorization: "Bearer " + user.access_token };
  } else {
    return {};
  }
};

class ApiService {
  async storeLogin(code) {
    const response = await axios.post(API_URL + "store/login", {
      code,
    });
    if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  async employeeLogin(auserid, token) {
    const response = await axios.get(
      API_URL + `store/login?auserid=${auserid}&token=${token}`
    );
    if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  unlockAvatar(avatarId, couponId) {
    return axios.put(
      API_URL + `avatar/${avatarId}/unlock`,
      couponId ? { couponId: couponId } : {},
      { headers: authHeader() }
    );
  }

  deleteAvatar(avatarId, reason) {
    return axios.put(
      API_URL + `avatar/${avatarId}/delete`,
      { reason: reason },
      { headers: authHeader() }
    );
  }
}

export default new ApiService();
