import ApiService from "./ApiService";

class AuthService {
  storeLogin(code) {
    return ApiService.storeLogin(code);
  }
  employeeLogin(auserid, token) {
    return ApiService.employeeLogin(auserid, token);
  }
  logout() {
    localStorage.removeItem("user");
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  isLoggedIn() {
    const user = this.getUser();
    return user && user.access_token ? true : false;
  };
}

export default new AuthService();
