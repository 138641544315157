import { Navigate } from "react-router-dom";
import AuthService from "./services/AuthService";

const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const checkJwtExpired = () => {
  const user = AuthService.getUser();
  if (user) {
    const decodedJwt = parseJwt(user.access_token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      AuthService.logout();
    }
  }
};

export default function ProtectedRoute({ children }) {
  checkJwtExpired();
  return AuthService.isLoggedIn() ? (
    children
  ) : (
    <Navigate replace to="/signin" />
  );
}
