import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    AppBar,
    Toolbar,
    Typography,
    Paper,
    Menu,
    MenuItem,
    IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Logout from "@mui/icons-material/Logout";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import AuthService from "../services/AuthService";
import ListItemIcon from "@mui/material/ListItemIcon";
import SnackbarAlert from "../components/SnackbarAlert";
import ApiService from "../services/ApiService";

const useStyles = makeStyles((theme) => ({
    button: {
        width: "50%",
    },
}));

const options = [
    "模型表情不自然",
    "臉部變形不自然",
    "臉部黯淡沒有美肌",
    "模型看起來很胖",
    "模型動作不自然",
    "其他",
];

export default function Home() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [openUnlock, setOpenUnlock] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [openAlert, setOpenAlert] = useState({
        open: false,
        text: "",
        severity: "error",
    });
    const [openCam, setOpenCam] = useState(true);
    const [data, setData] = useState("No result");
    const [value, setValue] = useState("0");
    const radioGroupRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleClose = () => {
        setOpenUnlock(false);
        setOpenDel(false);
        setOpenCam(true);
        setAnchorEl(null);
    };

    const handleLogout = () => {
        AuthService.logout();
        setAnchorEl(null);
        navigate("/signin");
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const parserJson = (data) => {
        if (data.action === "unlock") {
            setOpenUnlock(true);
            setOpenCam(false);
        } else if (data.action === "delete") {
            setOpenDel(true);
            setOpenCam(false);
        } else {
            setOpenAlert((prevState) => ({
                ...prevState,
                open: true,
                text: "QRCODE 非有效格式，請重新確認！",
                severity: "error",
            }));
        }
    };

    const unlockDialog = () => {
        return (
            <Dialog
                sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
                open={openUnlock}
                onClose={handleClose}
            >
                <DialogTitle id='alert-dialog-title'>解鎖模型</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        是否確定要解鎖此模型？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.button}
                        variant='outlined'
                        onClick={() => handleClose()}
                    >
                        取消
                    </Button>
                    <Button
                        className={classes.button}
                        variant='outlined'
                        onClick={handleUnlock}
                    >
                        確認
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const deleteDialog = () => {
        return (
            <Dialog
                sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
                maxWidth='xs'
                open={openDel}
            >
                <DialogTitle>刪除模型</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={1}>選擇刪除原因</DialogContentText>
                    <RadioGroup
                        ref={radioGroupRef}
                        aria-label='delete'
                        name='delete'
                        value={value}
                        onChange={handleChange}
                    >
                        {options.map((option, index) => (
                            <FormControlLabel
                                value={index.toString()}
                                key={option}
                                control={<Radio />}
                                label={option}
                            />
                        ))}
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.button}
                        variant='outlined'
                        onClick={() => handleClose()}
                    >
                        取消
                    </Button>
                    <Button
                        className={classes.button}
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        確認
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    // handle qrcode reader result
    const handleResult = (result, error) => {
        if (!!result) {
            setData(result?.text);
            try {
                parserJson(JSON.parse(result?.text));
            } catch (error) {
                parserJson({});
            }
        }
        if (!!error) {
            // ignore not found qrcode error
            if (error.name !== "NotFoundException") {
                console.info(error);
            }
        }
    };

    const handleUnlock = (event) => {
        handleClose();
        event.currentTarget.disabled = true;
        ApiService.unlockAvatar(
            JSON.parse(data).id,
            JSON.parse(data).couponId
        ).then(
            () => {
                setOpenAlert((prevState) => ({
                    ...prevState,
                    open: true,
                    text: "解鎖成功！",
                    severity: "success",
                }));
            },
            (error) => {
                let text = error.response.data.error.message
                    ? error.response.data.error.message
                    : "解鎖失敗，請重新嘗試或聯繫相關人員！";
                setOpenAlert((prevState) => ({
                    ...prevState,
                    open: true,
                    text: text,
                    severity: "error",
                }));
            }
        );
    };

    const handleDelete = (event) => {
        handleClose();
        event.currentTarget.disabled = true;
        ApiService.deleteAvatar(JSON.parse(data).id, value).then(
            () => {
                setOpenAlert((prevState) => ({
                    ...prevState,
                    open: true,
                    text: "刪除成功！",
                    severity: "success",
                }));
            },
            (error) => {
                let text = error.response.data.error.message
                    ? error.response.data.error.message
                    : "刪除失敗，請重新嘗試或聯繫相關人員！";
                setOpenAlert((prevState) => ({
                    ...prevState,
                    open: true,
                    text: text,
                    severity: "error",
                }));
            }
        );
    };

    return (
        <div>
            <AppBar
                position='static'
                style={{ background: "#2E3B55" }}
            >
                <Toolbar>
                    <QrCodeScannerIcon sx={{ mr: 2 }} />
                    <Typography
                        variant='h6'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    >
                        MyMoji QRCode 掃描工具
                    </Typography>
                    <div>
                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleMenu}
                            color='inherit'
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize='small' />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Paper
                elevation={0}
                square
                sx={{ height: "100vh", bgcolor: "black" }}
            >
                {openCam && (
                    <QrReader
                        onResult={handleResult}
                        videoStyle={{ height: "auto" }}
                        constraints={{ facingMode: "environment" }}
                    />
                )}
            </Paper>
            <SnackbarAlert
                alertState={openAlert}
                setAlertState={setOpenAlert}
            />
            {unlockDialog()}
            {deleteDialog()}
        </div>
    );
}
