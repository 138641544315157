import { React, useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthService from "../services/AuthService";
// import SnackbarAlert from "../components/SnackbarAlert";
import { AppContext } from "../App";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © MyMoji "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const { setLoading } = useContext(AppContext);
  const navigate = useNavigate();
  // const [openAlert, setOpenAlert] = useState({
  //   open: false,
  //   text: "",
  //   severity: "error",
  // });
  const [searchParams] = useSearchParams();
  const auserid = searchParams.get("auserid");
  const token = searchParams.get("token");

  useEffect(() => {
    if (auserid && token) {
      setLoading(true);
      AuthService.employeeLogin(
        encodeURIComponent(auserid),
        encodeURIComponent(token)
      )
        .then(() => {
          setLoading(false);
          navigate("/");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   if (!data.get("code")) {
  //     setOpenAlert((prevState) => ({
  //       ...prevState,
  //       open: true,
  //       text: "密碼不得為空",
  //     }));
  //     return;
  //   }

  //   setLoading(true);
  //   AuthService.storeLogin(data.get("code")).then(
  //     () => {
  //       navigate("/");
  //       setLoading(false);
  //     },
  //     (error) => {
  //       setOpenAlert((prevState) => ({
  //         ...prevState,
  //         open: true,
  //         text: "請確認密碼是否正確無誤！",
  //       }));
  //       setLoading(false);
  //       console.error(error);
  //     }
  //   );
  // };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        {/* <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="code"
                  label="密碼"
                  type="password"
                  id="code"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              登入
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} /> */}
      </Container>
      {/* <SnackbarAlert
        alertState={openAlert}
        setAlertState={setOpenAlert}
        alertTitle="登入失敗"
      /> */}
    </ThemeProvider>
  );
}
