import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";

export default function SnackbarAlert(props) {
  return (
    props.alertState && (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={props.alertState.open}
        autoHideDuration={5000}
        onClose={() =>
          props.setAlertState((prevState) => ({ ...prevState, open: false }))
        }
      >
        <Alert severity={props.alertState.severity}>
          <AlertTitle>{props.alertTitle}</AlertTitle>
          {props.alertState.text}
        </Alert>
      </Snackbar>
    )
  );
}
